<template>
  <main>
    <!-- prettier-ignore -->
    <section class="px-2 md:px-0 py-4">
      <div class="container">
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <router-link tag="li" :to="{name: 'home'}"><a>{{$t('breadcrumb_home')}}</a></router-link>
            <router-link class="is-hidden-mobile" tag="li" :to="{name: 'companies'}"><a>{{$t('breadcrumb_companies')}}</a></router-link>
            <router-link tag="li" :to="{ name: 'company', params: { cid } }"><a><company-name :company="company"/></a></router-link>
            <router-link class="is-hidden-mobile" tag="li" :to="{name: 'accounts-org', params: {cid, oid: 'default'}}"><a>{{$t('breadcrumb_accounts')}}</a></router-link>
            <router-link tag="li" :to="{name: 'account', params: {cid, aid}}"><a><account-name :account="account"/></a></router-link>
            <router-link tag="li" :to="{name: 'permissions', params: {cid, aid}}"><a>{{$t('breadcrumb_permissions')}}</a></router-link>
          </ul>
        </nav>
      </div>
    </section>
    <section class="section py-4" v-if="account">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-12-mobile is-9-tablet is-6-desktop">
            <h1 class="title is-3 pl-2"><account-name :account="account" /></h1>
            <div class="columns">
              <div class="column">
                <div class="field">
                  <label class="label">{{ $t('permissions_account') }}</label>
                  <div class="control">
                    <label class="radio" :disabled="!isManagerRW">
                      <input
                        type="radio"
                        name="status"
                        value="active"
                        v-model="status"
                        :disabled="!isManagerRW"
                      />
                      {{ $t('permissions_active') }}
                    </label>
                  </div>
                  <div class="control">
                    <label class="radio" :disabled="!isManagerRW">
                      <input
                        type="radio"
                        name="status"
                        value="archived"
                        v-model="status"
                        :disabled="!isManagerRW"
                      />
                      {{ $t('permissions_archived') }}
                    </label>
                  </div>
                </div>
                <sqr-progress v-show="statusUpdating" />
                <sqr-error-note class="field" :error="statusUpdateError" />
              </div>
              <div class="column">
                <div class="field">
                  <label class="label">{{ $t('permissions_roles') }}</label>
                  <div class="control">
                    <label class="checkbox" :disabled="!isManagerRW">
                      <input
                        type="checkbox"
                        value="manager"
                        v-model="roles"
                        :disabled="!isManagerRW"
                      />
                      {{ $t('permissions_manager') }}
                    </label>
                  </div>
                  <div class="control">
                    <label class="checkbox" :disabled="!isManagerRW">
                      <input
                        type="checkbox"
                        value="employee"
                        v-model="roles"
                        :disabled="!isManagerRW"
                      />
                      {{ $t('permissions_employee') }}
                    </label>
                  </div>
                  <div class="control">
                    <label class="checkbox" :disabled="!isManagerRW">
                      <input
                        type="checkbox"
                        value="planning"
                        v-model="roles"
                        :disabled="!isManagerRW"
                      />
                      {{ $t('permissions_planning') }}
                    </label>
                  </div>
                </div>
                <sqr-progress v-show="rolesUpdating" />
                <sqr-error-note class="field" :error="rolesUpdateError" />
              </div>
              <div class="column">
                <div class="field">
                  <label class="label">{{ $t('permissions_access') }}</label>
                  <div class="control">
                    <label class="radio" :disabled="!isManagerRW">
                      <input
                        type="radio"
                        name="access"
                        value="rw"
                        v-model="access"
                        :disabled="!isManagerRW"
                      />
                      {{ $t('permissions_rw') }}
                    </label>
                  </div>
                  <div class="control">
                    <label class="radio" :disabled="!isManagerRW">
                      <input
                        type="radio"
                        name="access"
                        value="ro"
                        v-model="access"
                        :disabled="!isManagerRW"
                      />
                      {{ $t('permissions_ro') }}
                    </label>
                  </div>
                </div>
                <sqr-progress v-show="rwUpdating" />
                <sqr-error-note class="field" :error="rwUpdateError" />
              </div>
            </div>
            <div class="buttons is-right" v-if="isAdmin">
              <sqr-button
                label="permissions_claims_refresh"
                color="white"
                @click="claimRefresh()"
                :is-loading="claimRefreshing"
              />
            </div>
            <sqr-error-note class="field" :error="claimRefreshError" />
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<style lang="postcss" scoped>
input {
  @apply mr-2;
}
label.checkbox,
label.radio {
  @apply p-2 border w-full mb-1;
}
label.checkbox:hover,
label.radio:hover {
  @apply bg-gray-100;
}
</style>

<script>
import { mapGetters } from 'vuex';
import firebase from 'firebase/app';
const FieldValue = firebase.firestore.FieldValue; // TODO
import { contains } from 'ramda';

import account from './account';

import SqrProgress from '@/sqrd/SqrProgress';
import SqrErrorNote from '@/sqrd/SqrErrorNote';
import SqrButton from '@/sqrd/SqrButton';

import CompanyName from '@/components/CompanyName';
import AccountName from '@/components/AccountName';

export default {
  name: 'Permissions',
  components: {
    SqrProgress,
    SqrErrorNote,
    SqrButton,
    CompanyName,
    AccountName,
  },
  mixins: [account],
  computed: {
    ...mapGetters('perms', ['isManagerRW', 'isAdmin']),
    status: {
      get() {
        if (!this.company) return 'unknown';
        return contains(this.aid, this.company?.enabledUids ?? [])
          ? 'active'
          : 'archived';
      },
      async set(status) {
        try {
          this.statusUpdating = true;
          this.statusUpdateError = null;
          const companyRef = this.$db().collection('companies').doc(this.cid);
          if (status === 'active') {
            await companyRef.update({
              enabledUids: FieldValue.arrayUnion(this.aid),
            });
          } else {
            await companyRef.update({
              enabledUids: FieldValue.arrayRemove(this.aid),
            });
          }
        } catch (error) {
          this.statusUpdateError = error;
        } finally {
          this.statusUpdating = false;
        }
      },
    },
    roles: {
      get() {
        if (!this.company) return [];
        let res = [];
        if (contains(this.aid, this.company?.managerUids ?? [])) {
          res.push('manager');
        }
        if (contains(this.aid, this.company?.employeeUids ?? [])) {
          res.push('employee');
        }
        if (contains(this.aid, this.company?.planningUids ?? [])) {
          res.push('planning');
        }
        return res;
      },
      async set(roles) {
        try {
          this.rolesUpdating = true;
          this.rolesUpdateError = null;
          const companyRef = this.$db().collection('companies').doc(this.cid);
          const payload = {};
          if (contains('manager', roles)) {
            payload['managerUids'] = FieldValue.arrayUnion(this.aid);
          } else {
            payload['managerUids'] = FieldValue.arrayRemove(this.aid);
          }
          if (contains('employee', roles)) {
            payload['employeeUids'] = FieldValue.arrayUnion(this.aid);
          } else {
            payload['employeeUids'] = FieldValue.arrayRemove(this.aid);
          }
          if (contains('planning', roles)) {
            payload['planningUids'] = FieldValue.arrayUnion(this.aid);
          } else {
            payload['planningUids'] = FieldValue.arrayRemove(this.aid);
          }
          await companyRef.update(payload);
        } catch (error) {
          this.rolesUpdateError = error;
        } finally {
          this.rolesUpdating = false;
        }
      },
    },
    access: {
      get() {
        if (!this.company) return 'unknown';
        return contains(this.aid, this.company?.rwUids ?? []) ? 'rw' : 'ro';
      },
      async set(status) {
        try {
          this.rwUpdating = true;
          this.rwUpdateError = null;
          const companyRef = this.$db().collection('companies').doc(this.cid);
          if (status === 'rw') {
            await companyRef.update({
              rwUids: FieldValue.arrayUnion(this.aid),
            });
          } else {
            await companyRef.update({
              rwUids: FieldValue.arrayRemove(this.aid),
            });
          }
        } catch (error) {
          this.rwUpdateError = error;
        } finally {
          this.rwUpdating = false;
        }
      },
    },
  },
  data() {
    return {
      statusUpdating: false,
      statusUpdateError: null,
      rolesUpdating: false,
      rolesUpdateError: null,
      rwUpdating: false,
      rwUpdateError: null,
      claimRefreshing: false,
      claimRefreshError: null,
    };
  },
  methods: {
    async claimRefresh() {
      try {
        this.claimRefreshing = true;
        this.claimRefreshError = null;
        const uid = this.aid;
        const ref = this.$db().collection('users-claims').doc(uid);
        await ref.set({ status: 'refreshing' }, { merge: true });
      } catch (error) {
        this.claimRefreshError = error;
      } finally {
        this.claimRefreshing = false;
      }
    },
  },
};
</script>
